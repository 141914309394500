import { observable } from "mobx";

const commonStore = observable(
  {
    isLoading: false,

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },
  }
);

export default commonStore;
