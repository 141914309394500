import axios from 'axios';
import { LocalStorage } from './helpers';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 600000, // timeout 10 minutes due to on production we have alot of datas
});

instance.interceptors.response.use(undefined, err => {
  if ((err.response.status === 401 || err.response.status === 403) && err.response.config && !err.response.config.__isRetryRequest) {
    LocalStorage.clearToken();
    LocalStorage.clearStateGeneral('user');
    window.location.href = '/login';
  }
  return Promise.reject(err);
});

export default instance;
