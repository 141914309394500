export const ContactType = {
  Candidate: 1,
  Client: 2,
  Lead: 3
};

export const SurveyCustomFieldType = {
  List: "List",
  Text: "Text"
};

export const OptionSalaryRate = [
  {
    value: "Hour",
    text: "Hourly Rate"
  },
  {
    value: "Day",
    text: "Daily Rate"
  },
  {
    value: "Week",
    text: "Weekly Rate"
  },
  {
    value: "Month",
    text: "Monthly Rate"
  },
  {
    value: "Year",
    text: "Yearly Rate"
  },
];

export const AtsSyncVendor = {
  JobAdder: 1
};

export const SurveyStateType = {
  Active: 1,
  Inactive: 2
};

export const SurveyFormFace = {
  Autoscroll: "autoscroll",
  Chat: "chat",
  Classic: "classic"
};
