import { observable, action } from "mobx";
import { ApiService, ApiUrl } from "../helpers";
import Alert from 'react-s-alert';

const lookupStore = observable(
  {
    customFields: [],
    currentWorkTypes: [],
    countries: [],

    setCountries(countries) {
      this.countries = [...countries];
    },

    setCustomFields(customFields) {
      this.customFields = [...customFields];
    },

    setCurrentWorkTypes(currentWorkTypes) {
      this.currentWorkTypes = [...currentWorkTypes];
    },

    getCustomFieldsPublic(token, type) {
      const data = {
        token,
        type
      };

      return (ApiService.getDetailWithParams(ApiUrl.getCustomFieldsPublic, '', data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setCustomFields(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getCountriesPublic(token, type) {
      const data = {
        token,
        type
      };

      return (ApiService.getDetailWithParams(ApiUrl.getJACountriesPublic, '', data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setCountries(response.data.result.items);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },

    getCurrentWorkTypesPublic(token, type) {
      const data = {
        token,
        type
      };

      return (ApiService.getDetailWithParams(ApiUrl.getJAWorkTypesPublic, '', data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // TODO HERE if something necessary, for now just return data exactly from API
              this.setCurrentWorkTypes(response.data.result.items);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(err => {
          return Promise.reject(err);
        })
      ));
    },
  }
);

export default lookupStore;
