import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { History } from './helpers';
import Alert from 'react-s-alert';
import PromiseLoader from './PromiseLoader';

// Import react-s-alert styling
import 'react-s-alert/dist/s-alert-default.css';
// choose the react-s-alert effect
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import 'react-s-alert/dist/s-alert-css-effects/flip.css';
import 'react-s-alert/dist/s-alert-css-effects/genie.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';
const loading = () => <PromiseLoader></PromiseLoader>;

const SurveyLandingPage = React.lazy(() => import('./views/SurveyLandingPage'));

class App extends Component {

  render() {
    return (
      <React.Fragment>
        <Alert
          stack={{ limit: 5, spacing: 50 }}
          effect="jelly"
          position="top-right"
          timeout={5000}
          offset={50}
        />
        <Router history={History}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/:token" name="Survey Landing Page" render={props => <SurveyLandingPage {...props} />} />
              <Redirect to="/404" />
            </Switch>
          </React.Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
