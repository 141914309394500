import { observable, action } from "mobx";
import { ApiService, ApiUrl, Constants, Enum } from "../helpers";
import Alert from 'react-s-alert';

const atsStore = observable(
  {
    code: undefined,
    atsAccessToken: undefined,
    atsCurrentEmail: undefined,
    isNeedRelogin: false,
    atsFieldCandidate: [],
    atsFieldClient: [],
    atsFieldForMergeTags: [],
    integratedAtsFields: [],
    atsSyncStatusInfo: undefined,
    customFields: [],

    setCode(code) {
      this.code = code;
    },

    setAtsAccessToken(atsAccessToken) {
      this.atsAccessToken = atsAccessToken;
    },

    setAtsCurrentEmail(atsCurrentEmail) {
      this.atsCurrentEmail = atsCurrentEmail;
    },

    setIsNeedRelogin(isNeedRelogin) {
      this.isNeedRelogin = isNeedRelogin;
    },

    setAtsFieldCandidate(atsFieldCandidate) {
      this.atsFieldCandidate = [...atsFieldCandidate];
    },

    setAtsFieldClient(atsFieldClient) {
      this.atsFieldClient = [...atsFieldClient];
    },

    setAtsFieldForMergeTags(atsFieldForMergeTags) {
      this.atsFieldForMergeTags = [...atsFieldForMergeTags];
    },

    setIntegratedAtsFields(integratedAtsFields) {
      this.integratedAtsFields = integratedAtsFields;
    },

    setCustomFields(customFields) {
      this.customFields = customFields;
    },

    setAtsSyncStatusInfo(syncStatusInfo) {
      this.atsSyncStatusInfo = syncStatusInfo;
    },

    getAtsSyncInfo() {
      return this.atsSyncStatusInfo;
    },

    setAtsFieldsForCandidateAndClient(atsFields = []) {
      const filterAtsFieldsCandidate = atsFields.filter(item =>
        item.tokenVariable !== Constants.AtsFieldsTokenVariable.CandidatePhoto &&
        item.tokenVariable !== Constants.AtsFieldsTokenVariable.CandidateResume &&
        item.tokenVariable !== Constants.AtsFieldsTokenVariable.CandidateNPS &&
        item.tokenVariable !== Constants.AtsFieldsTokenVariable.UrlUpdateData &&
        item.contactType === Enum.ContactType.Candidate);
      const filterAtsFieldsClient = atsFields.filter(item =>
        item.contactType === Enum.ContactType.Client);
      this.setAtsFieldCandidate(filterAtsFieldsCandidate);
      this.setAtsFieldClient(filterAtsFieldsClient);

      // only show clientFirstName in mergetags list (PBI #4087)
      const filterMergeTagsClient = filterAtsFieldsClient.filter(item =>
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ClientFirstName
      );
      this.setAtsFieldForMergeTags([...this.atsFieldCandidate, ...filterMergeTagsClient]);
    },

    getAtsField() {
      return (ApiService.get(ApiUrl.getAtsField).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              const { atsFields = [] } = response.data.result;
              this.setAtsFieldsForCandidateAndClient(atsFields);
              const showIntegratedAtsFields = response.data.result.atsFields.filter(item => item.isShow ||
                item.tokenVariable === Constants.AtsFieldsTokenVariable.CandidateResume ||
                item.tokenVariable === Constants.AtsFieldsTokenVariable.CandidateNPS);
              this.setIntegratedAtsFields(showIntegratedAtsFields);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getLandingPageAtsField(token, type) {
      const data = {
        token,
        type
      };

      return (ApiService.getDetailWithParams(ApiUrl.getLandingPageAtsField, '', data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAtsFieldCandidate(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getAtsSyncStatus() {
      return (ApiService.get(`/AtsSync/${Enum.AtsSyncVendor.JobAdder}/GetSyncStatus`).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAtsSyncStatusInfo(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    toggleField(id, state) {
      const data = {
        state: state,
        fieldId: id
      };

      return (ApiService.edit(ApiUrl.setAtsField, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              const { atsFields = [] } = response.data.result;
              this.setAtsFieldsForCandidateAndClient(atsFields);
              const showIntegratedAtsFields = response.data.result.atsFields.filter(item => item.isShow || item.tokenVariable === Constants.AtsFieldsTokenVariable.CandidateResume);
              this.setIntegratedAtsFields(showIntegratedAtsFields);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    toggleAllField(state, contactType = Enum.ContactType.Candidate) {
      const data = {
        state,
        contactType
      };

      return (ApiService.edit(ApiUrl.setAllAtsField, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              const { atsFields = [] } = response.data.result;
              this.setAtsFieldsForCandidateAndClient(atsFields);
              const showIntegratedAtsFields = response.data.result.atsFields.filter(item => item.isShow || item.tokenVariable === Constants.AtsFieldsTokenVariable.CandidateResume);
              this.setIntegratedAtsFields(showIntegratedAtsFields);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    syncNow() {
      return (ApiService.get(ApiUrl.syncNow).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAtsSyncStatusInfo({ status: "In Progress", lastSync: this.getAtsSyncInfo().lastSync });
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    syncFields() {
      return (ApiService.get(ApiUrl.syncFields).then(
        action (
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              Alert.success(Constants.ALERT_SYNC_SUCCESS);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch (
        action (
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getCustomFields(){
      return (ApiService.get(ApiUrl.customFields).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError){
              this.setCustomFields(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },
  }
);

export default atsStore;
