import { observable, action } from "mobx";
import { HeaderTabName } from "../helpers/Constants";
import { ApiUrl, ApiService, Enum } from "../helpers";
import { convertTripettoData } from "../helpers/TripettoHelper"
import { SurveyStateType, SurveyFormFace } from "../helpers/enum";
import Alert from 'react-s-alert';

const surveyStore = observable(
  {
    surveyData: {},
    surveyStyles: null,
    surveyFormFace: SurveyFormFace.Autoscroll,

    setSurveyData(surveyData) {
      this.surveyData = surveyData;
      this.isSurveyFormDirty = true;
    },

    setSurveyStyles(styles) {
      this.surveyStyles = styles;
      this.isSurveyFormDirty = true;
    },

    setSurveyFormFace(surveyFormFace) {
      this.surveyFormFace = surveyFormFace;
      this.isSurveyFormDirty = true;
    },

    loadSurveyByToken(token) {
      return (ApiService.getDetailWithParams(ApiUrl.getSurveyByToken, token).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              if (response.data.result) {
                const { definitionStructure } = response.data.result;

                this.setSurveyData(JSON.parse(definitionStructure).definition);
                this.setSurveyFormFace(JSON.parse(definitionStructure).formFace);
                this.setSurveyStyles(JSON.parse(definitionStructure).styles);
              }
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    loadSurveyClientByToken(token) {
      return (ApiService.getDetailWithParams(ApiUrl.getSurveyClientByToken, token).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              if (response.data.result) {
                const definitionStructure = JSON.parse(response.data.result.definitionStructure);

                this.setSurveyData(definitionStructure.definition);
                this.setSurveyFormFace(definitionStructure.formFace);
                this.setSurveyStyles(definitionStructure.styles);
              }
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    submitSurveyResult(surveyData, dataToken) {
      const data = {
        response: convertTripettoData(surveyData),
        token: dataToken
      }
      return (ApiService.post(ApiUrl.submitSurvey, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              return Promise.resolve(response.data);
            }
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    submitSurveyClientResult(surveyData, dataToken) {
      const data = {
        response: convertTripettoData(surveyData),
        token: dataToken
      }
      return (ApiService.post(ApiUrl.submitSurveyClient, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              return Promise.resolve(response.data);
            }
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },
  }
);

export default surveyStore;
