import Alert from 'react-s-alert';

function isImageSize500(image) {
  try {
    //check if image is jpg or png
    if (!image.name.match(/.(jpg|jpeg|png)$/i)) {
      Alert.error('Image format is not supported!')
      return false
    }
    //check if image size is more than 500kb = 512000 bytes
    if (image.size > 512000) {
      Alert.error("Image format is can't be more than 500 KB!");
      return false
    }
    return true
  } catch (error) {
    return false
  }
}

function isImageSize1mb(image) {
  try {
    //check if image is jpg or png
    if (!image.name.match(/.(jpg|jpeg|png)$/i)) {
      Alert.error('Image format is not supported!')
      return false
    }
    //check if image size is more than 1MB = 1024000 bytes
    if (image.size > 1024000) {
      Alert.error("Image format is can't be more than 1 MB!");
      return false
    }
    return true
  } catch (error) {
    return false
  }
}

function isImageSize5mb(image) {
  try {
    //check if image is jpg or png
    if (!image.name.match(/.(jpg|jpeg|png|bmp)$/i)) {
      Alert.error('Image format is not supported!')
      return false
    }
    //check if image size is more than 5MB = 5242880 bytes
    if (image.size > 5242880) {
      Alert.error("Image format is can't be more than 5 MB!");
      return false
    }
    return true
  } catch (error) {
    return false
  }
}

function isVideoSize5mb(video) {
  try {
    //check if video is jpg or png
    if (!video.name.match(/.(mp4)$/i)) {
      Alert.error('Other than MP4 format is not supported!')
      return false
    }
    //check if video size is more than 5MB = 5242880 bytes
    if (video.size > 5242880) {
      Alert.error("Video size can't be more than 5 MB!");
      return false
    }
    return true
  } catch (error) {
    return false
  }
}


function isGifSize2mb(gif) {
  try {
    //check if gif is jpg or png
    if (!gif.name.match(/.(gif)$/i)) {
      Alert.error('This format is not supported!')
      return false
    }
    //check if gif size is more than 2MB = 2097152 bytes
    if (gif.size > 2097152) {
      Alert.error("GIF size can't be more than 2 MB!");
      return false
    }
    return true
  } catch (error) {
    return false
  }
}

const convertBase64toFileObject = (dataurl, filename) => {

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export {
  isImageSize500,
  isVideoSize5mb,
  isGifSize2mb,
  isImageSize1mb,
  isImageSize5mb,
  convertBase64toFileObject
};
