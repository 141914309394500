import { useEffect, useState } from 'react';

export const saveToken = data => {
  try {
    const serializedData = JSON.stringify(data);
    localStorage.setItem('token', serializedData);
  } catch (err) {
    console.error(err);
  }
};

export const loadToken = () => {
  try {
    const serializedState = localStorage.getItem('token');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  }
  catch (err) {
    console.error(err);
    return undefined;
  }
};

export const clearToken = () => {
  try {
    localStorage.removeItem('token');
  } catch (err) {
    console.error(err);
  }
};

export const isAuthenticated = () => {
  const state = JSON.parse(localStorage.getItem('token'));
  if (state === null) {
    return false;
  }
  return true;
};

// =================================================
// If we need to store/get any items into localstore
// =================================================

export const saveGeneral = (key, value) => {
  try {
    const serializedUser = JSON.stringify(value);
    localStorage.setItem(key, serializedUser);
  } catch (err) {
    console.error(err);
  }
};

export const loadGeneral = key => {
  try {
    const item = localStorage.getItem(key);
    if (item === null) {
      return undefined;
    }
    return JSON.parse(item);
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const updateGeneral = (key, name, value) => {
  try {
    let item = localStorage.getItem(key);
    if (item === null) {
      return undefined;
    }
    item = item ? JSON.parse(item) : {};
    item[name] = value;

    localStorage.setItem(key, JSON.stringify(item));
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const clearStateGeneral = key => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.error(err);
  }
};

export const useLocalStorage = (key, initialValue) => {
  if (localStorage.getItem(key) === null) {
    window.location.href = '/login';
  }

  const localValue = JSON.parse(localStorage.getItem(key));
  const [localState, updateLocalState] = useState(localValue);
  const localStorageChanged = e => {
    if (e.key === key) {
      updateLocalState(JSON.parse(e.newValue));
    }
  }

  const setLocalState = value => {
    localStorage.setItem(key, JSON.stringify(value));
    updateLocalState(value);
  }

  useEffect(() => {
    window.addEventListener('storage', localStorageChanged);
    return () => {
      window.removeEventListener('storage', localStorageChanged);
    }
  });

  return [localState, setLocalState]
}