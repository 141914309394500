import React from "react";
import "./PromiseLoader.css";
import { commonStore } from "./stores";
import { observer } from "mobx-react";
import loaderRoi from "./assets/img/loader/loader-roi.gif";

const PromiseLoader = observer(() => {
  const { isLoading } = commonStore;

  return isLoading && (
    <div className="promise-loader">
      <img src={loaderRoi} alt="loader-roi" />
    </div>
  )
});

export default PromiseLoader;
