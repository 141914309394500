const ApiUrl = {
  getSurveyByToken: "/Surveys/GetSurveyByToken/",
  getSurveyClientByToken: "/Surveys/Client/",
  getCustomFieldsPublic: "Lookup/Candidate/CustomFieldsPublic",
  getJACountriesPublic: "/Lookup/Jobadder/CountriesPublic",
  getJAWorkTypesPublic: "/Lookup/Jobadder/WorkTypesPublic",
  submitSurvey: "/Surveys/SubmitSurvey/",
  submitSurveyClient: "/Surveys/Client/Submit/",
};

export default ApiUrl;
